<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M120.5,140.1H108c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4h12.5c2.4,0,4.4-2,4.4-4.4   C124.9,142.1,122.9,140.1,120.5,140.1z"/>
      <path class="st0" d="M86.8,140.1H74.2c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4h12.5c2.4,0,4.4-2,4.4-4.4   C91.2,142.1,89.2,140.1,86.8,140.1z"/>
      <path class="st0" d="M197.7,124l-32.3-90.7c-1.3-3.6-4.7-6-8.5-6H43.2c-3.8,0-7.2,2.4-8.5,6L2.3,124c-0.3,1-0.5,2-0.5,3v36.5   c0,5,4.1,9.1,9.1,9.1h178.3c5,0,9.1-4.1,9.1-9.1v-36.5C198.2,126,198,125,197.7,124z M83,78.7l6.8-31.8c0-0.2,0.2-0.3,0.3-0.3h16.3   c0.2,0,0.4,0.2,0.3,0.4l-5.7,19.2c-0.1,0.2,0.1,0.5,0.3,0.5h15.2c0.3,0,0.5,0.3,0.3,0.5L90.3,105c-0.2,0.3-0.7,0.1-0.6-0.3L96,79.6   c0.1-0.2-0.1-0.4-0.3-0.4H83.3C83.1,79.1,83,78.9,83,78.7z M185.9,158.7c0,1.1-0.9,2-2,2H16.1c-1.1,0-2-0.9-2-2v-28.4   c0-1.1,0.9-2,2-2h167.8c1.1,0,2,0.9,2,2V158.7z"/>
      <path class="st0" d="M55,139.2H28c-0.4,0-0.8,0.4-0.8,0.8v9.1c0,0.4,0.4,0.8,0.8,0.8H55c0.4,0,0.8-0.4,0.8-0.8V140   C55.8,139.6,55.4,139.2,55,139.2z"/>
      <path class="st0" d="M160.1,131.9c-7,0-12.7,5.7-12.7,12.7c0,7,5.7,12.7,12.7,12.7c7,0,12.7-5.7,12.7-12.7   C172.7,137.6,167.1,131.9,160.1,131.9z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconPowerBank',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

